import React, { ReactNode } from 'react';

import styles from './styles.module.scss';

interface InfoBoxProps {
  header: string;
  description: string;
  image: ReactNode;
}

const InfoBox = ({ header, description, image }: InfoBoxProps) => {
  return (
    <div className={styles.infoBox}>
      <div className={styles.imageSection}>{image}</div>
      <div className={styles.textSection}>
        <div className={styles.header}>{header}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default InfoBox;
