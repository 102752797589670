/* eslint-disable camelcase */
/* eslint-disable camelcase */

import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { matchPath } from 'react-router';
import { combineEpics, ofType } from 'redux-observable';
import { of, EMPTY, from, combineLatest } from 'rxjs';
import { filter, map, mergeMap, pairwise, take } from 'rxjs/operators';

import { unsubscribeBasketOrderOrderbook } from 'actions';
import { setMarketsSelectedTab } from 'actions/markets';
import {
  optionChainToggleExpiryDate,
  updateOptionChainAssetSymbol,
} from 'actions/optionChain';
import { unsubscribeL2Orderbook, unsubscribeObRt } from 'actions/socket';
import { getNearestStrikePriceForOptionChain } from 'helpers/optionsChain';
import { head, pipe, sortBy } from 'helpers/ramda';
import {
  getDefaultAssetAndExpiryDate,
  getFilteredDataForOptionChains,
  isEmpty,
  isMoveOptions,
  isTradePage,
  isTurbo,
  selectedOptionChainProductList,
} from 'helpers/utils';
import {
  optionsChainSelectedAssetTypeSelector,
  optionsChainSelectedDateSelector,
  optionsInfoSelector,
} from 'selectors/optionChainSeletor';
import { assetSelector, productsSelector } from 'selectors/tradeSelectors';

import { changeSelectedProduct } from '../actions/trade';
import { filterObjectToList, findObject, isNotEmpty } from '../ramdax';

// if (!matchedPath) {
//   matchedPath = matchPath(
//     path,
//     '/tradingview/:chartType/:contractType/:assetSymbol/:productSymbol'
//   );
// }

const chartLocationEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    map(({ payload }) => payload.location.pathname),
    map(path =>
      matchPath(path, '/tradingview/:chartType/:contractType/:assetSymbol/:productSymbol')
    ),
    filter(Boolean),
    filter(match => match.isExact),
    mergeMap(({ params }) => {
      const products = productsSelector(state$.value);
      if (isNotEmpty(products)) {
        return of({ params, products });
      }
      return state$.pipe(
        map(productsSelector),
        filter(isNotEmpty),
        take(1),
        map(_products => ({ params, products: _products }))
      );
    }),
    map(({ params, products }) => dispatch => {
      const { productSymbol } = params;
      if (productSymbol) {
        const product = findObject(({ symbol }) => symbol === productSymbol, products);
        if (isNotEmpty(product)) {
          return dispatch(changeSelectedProduct(product.id));
        }
      }
      return EMPTY;
    })
  );

const findMarketRedirect = ({
  params,
  optionsInfo,
  optionsChainSelectedAsset,
  optionsChainSelectedExpiryDate,
  search,
}) => {
  const { contractType, assetSymbol, expiryDate } = params;

  // if assetSymbol is undefined then redirect to BTC markets page making BTC as asset symbol
  // if expiryDate is undefined then redirect to tomorrows dates markets page making expiryDate tomorrows date
  // if both are present iterate over optionsInfo, filter for contract_type put and call options and match with assetSymbol and filter settlement date with expiryDate and return the first match , return first element if not found
  // structure of optionsInfo is {
  //   contract_type: 'put_options' | 'call_options',
  //  data : [
  //   {
  //     underlying_asset: 'BTC',
  //     settlement_date: ["2024-07-19T12:00:00Z",
  //     "2024-07-20T12:00:00Z",
  //     "2024-07-21T12:00:00Z",
  //     "2024-07-26T12:00:00Z",
  //     "2024-08-02T12:00:00Z",
  //     "2024-08-30T12:00:00Z"
  //   },
  //   {
  //     underlying_asset: 'ETH',
  //     settlement_date: ["2024-07-19T12:00:00Z",
  //     "2024-07-20T12:00:00Z",
  //     "2024-07-21T12:00:00Z",
  //     "2024-07-26T12:00:00Z",
  //     "2024-08-02T12:00:00Z",
  //     "2024-08-30T12:00:00Z"
  //   }
  // ]
  // }
  // keep expiryDate in zulu format , hence conversion might be required

  const actions = [setMarketsSelectedTab(contractType)];

  // code
  switch (contractType) {
    case 'options_chain':
    case 'options': {
      if (isEmpty(assetSymbol) || assetSymbol === 'undefined') {
        const newAssetSymbol = optionsChainSelectedAsset || 'BTC';
        actions.push(
          replace({
            pathname: `/${contractType}/markets/${newAssetSymbol}`,
            search,
          })
        );
        break;
      }

      const settlementDates = optionsInfo
        .filter(({ contract_type }) => contract_type === 'call_options')[0]
        .data.filter(({ asset }) => asset === assetSymbol)[0].settlement_time;

      if (
        isEmpty(expiryDate) ||
        expiryDate === 'undefined' ||
        !settlementDates.find(date => date === expiryDate)
      ) {
        const newExpiryDate = settlementDates.find(
          date => date === optionsChainSelectedExpiryDate
        )
          ? optionsChainSelectedExpiryDate
          : settlementDates[0];

        actions.push(
          replace({
            pathname: `/${contractType}/markets/${assetSymbol}/${newExpiryDate}`,
            search,
          })
        );
        break;
      }

      if (expiryDate !== optionsChainSelectedExpiryDate) {
        actions.push(optionChainToggleExpiryDate(expiryDate));
      }

      if (assetSymbol !== optionsChainSelectedAsset) {
        actions.push(updateOptionChainAssetSymbol(assetSymbol));
      }
      break;
    }
    default:
      break;
  }

  return from(actions);
};

// Noted: tried writing this fn in recursive function call
// but output of all the branches is not same,
// sometimes, we are invoking `changeSelectedProduct` and other times `replace`
const findProductAndRedirect = ({ params, products, assets, search }) => {
  const { contractType, productSymbol, assetSymbol } = params;
  if (productSymbol) {
    const product = findObject(({ symbol }) => symbol === productSymbol, products);
    if (isNotEmpty(product)) {
      return changeSelectedProduct(product.id);
    }
  }

  if (assetSymbol) {
    const product = pipe(
      filterObjectToList(({ contract_type }) => contract_type === contractType),
      filterObjectToList(
        ({ underlying_asset, state }) =>
          underlying_asset.symbol === assetSymbol && state === 'live'
      ),
      sortBy(({ ui_config }) => ui_config.sort_priority),
      head
    )(products);

    if (product && isNotEmpty(product)) {
      return replace(`/${contractType}/trade/${assetSymbol}/${product.symbol}`);
    }
  }

  switch (contractType) {
    case 'interest_rate_swaps': {
      const product = pipe(
        filterObjectToList(
          ({ contract_type, state }) =>
            contract_type === 'interest_rate_swaps' && state === 'live'
        ),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);

      const underlyingAssetSymbol =
        product?.underlying_asset?.symbol || assets[product.underlying_asset_id].symbol;
      return product
        ? replace(`/${contractType}/trade/${underlyingAssetSymbol}/${product?.symbol}`)
        : replace(`/interest_rate_swaps/markets`);
    }
    case 'futures': {
      const product = pipe(
        filterObjectToList(
          ({ contract_type, state }) =>
            (contract_type === 'futures' || contract_type === 'perpetual_futures') &&
            state === 'live'
        ),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);

      const underlyingAssetSymbol =
        product?.underlying_asset?.symbol || assets[product.underlying_asset_id].symbol;
      return replace({
        pathname: `/futures/trade/${underlyingAssetSymbol}/${product?.symbol}`,
        search,
      });
    }
    // case 'options': {
    //   const product = R.pipe(
    //     filterObjectToList(({ contract_type, state , underlying_asset }) => {
    //       return contract_type === 'call_options' && state === 'live' && underlying_asset.symbol === 'BTC';
    //     }),
    //     R.sortBy(({ ui_config }) => ui_config.sort_priority),
    //     R.head
    //   )(products);
    //   return replace(
    //     `/options/trade/${product.underlying_asset.symbol}/${product.symbol}`
    //   );
    // }
    case 'move_options': {
      const product = pipe(
        filterObjectToList(({ contract_type, state }) => {
          return isMoveOptions(contract_type) && state === 'live';
        }),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);

      const underlyingAssetSymbol =
        product?.underlying_asset?.symbol || assets[product.underlying_asset_id].symbol;
      return product
        ? replace(`/move_options/trade/${underlyingAssetSymbol}/${product?.symbol}`)
        : replace(`/move_options/markets`);
    }
    case 'turbo_options': {
      const product = pipe(
        filterObjectToList(
          ({ contract_type, state }) => isTurbo(contract_type) && state === 'live'
        ),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);

      const underlyingAssetSymbol =
        product?.underlying_asset?.symbol || assets[product.underlying_asset_id].symbol;
      return product
        ? replace(`/turbo_options/trade/${underlyingAssetSymbol}/${product?.symbol}`)
        : replace(`/turbo_options/markets`);
    }
    case 'spreads': {
      const product = pipe(
        filterObjectToList(
          ({ contract_type, state }) =>
            (contract_type === 'spreads' || contract_type === 'interest_rate_swaps') &&
            state === 'live'
        ),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);

      const underlyingAssetSymbol =
        product?.underlying_asset?.symbol || assets[product.underlying_asset_id].symbol;
      return product
        ? replace(`/spreads/trade/${underlyingAssetSymbol}/${product?.symbol}`)
        : replace(`/spreads/markets`);
    }
    case 'spot': {
      const product = pipe(
        filterObjectToList(
          ({ contract_type, state }) => contract_type === 'spot' && state === 'live'
        ),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);

      const underlyingAssetSymbol =
        product?.underlying_asset?.symbol || assets[product.underlying_asset_id].symbol;
      return replace(`/spot/trade/${underlyingAssetSymbol}/${product?.symbol}`);
    }
    case 'options':
    case 'options_chain': {
      const optionsChainList = selectedOptionChainProductList(Object.values(products));

      const { tableData, expiryTimesDropDownList, underlyingAssetSymbolList } =
        optionsChainList;

      const { defaultSelectedAsset, defaultExpiryDate } = getDefaultAssetAndExpiryDate(
        expiryTimesDropDownList,
        underlyingAssetSymbolList
      );

      const selectedType = null;
      const filteredOptions = getFilteredDataForOptionChains(
        tableData,
        defaultExpiryDate,
        defaultSelectedAsset,
        selectedType
      );

      if (filteredOptions.length > 0) {
        const row = getNearestStrikePriceForOptionChain(filteredOptions);
        return replace({
          pathname: `/${contractType}/trade/${defaultSelectedAsset}/${row.symbol}`,
          search,
        });
      }
      const newfilteredOptions = getFilteredDataForOptionChains(
        tableData,
        expiryTimesDropDownList[defaultSelectedAsset] &&
          expiryTimesDropDownList[defaultSelectedAsset][0],
        defaultSelectedAsset,
        selectedType
      );
      if (newfilteredOptions.length > 0) {
        const row = getNearestStrikePriceForOptionChain(newfilteredOptions);
        return replace({
          pathname: `/${contractType}/trade/${defaultSelectedAsset}/${row.symbol}`,
          search,
        });
      }
      return replace(`/options_chain/markets`);
    }
    case 'options_combos': {
      const product = pipe(
        filterObjectToList(
          ({ contract_type, state }) =>
            contract_type === 'options_combos' && state === 'live'
        ),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);

      const underlyingAssetSymbol =
        product?.underlying_asset?.symbol || assets[product.underlying_asset_id].symbol;
      return product
        ? replace(`/options_combos/trade/${underlyingAssetSymbol}/${product.symbol}`)
        : replace(`/options_combos/markets`);
    }
    default:
      // eslint-disable-next-line consistent-return, no-useless-return
      return;
  }
};

const marketsLocationEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    map(({ payload }) => ({
      pathname: payload.location.pathname,
      search: payload.location.search,
    })),
    map(({ pathname, search }) => {
      const match = matchPath(
        pathname,
        '/:contractType?/markets/:assetSymbol?/:expiryDate?'
      );
      return { match, search };
    }),
    filter(({ match }) => Boolean(match) && match.isExact),
    mergeMap(({ match, search }) => {
      const optionsInfo = optionsInfoSelector(state$.value);
      const optionsChainSelectedAsset = optionsChainSelectedAssetTypeSelector(
        state$.value
      );
      const optionsChainSelectedExpiryDate = optionsChainSelectedDateSelector(
        state$.value
      );

      if (!isEmpty(optionsInfo)) {
        return of({
          params: match?.params,
          optionsInfo,
          optionsChainSelectedAsset,
          optionsChainSelectedExpiryDate,
        });
      }
      return state$.pipe(
        map(optionsInfoSelector),
        filter(isNotEmpty),
        take(1),
        map(_optionsInfo => ({
          params: match?.params,
          optionsInfo: _optionsInfo,
          optionsChainSelectedAsset,
          optionsChainSelectedExpiryDate,
          search,
        }))
      );
    }),
    mergeMap(findMarketRedirect)
  );

const tradeLocationEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    map(({ payload }) => ({
      path: payload.location.pathname,
      search: payload.location.search,
    })),
    map(({ path, search }) => ({
      match: matchPath(path, '/:contractType?/trade/:assetSymbol?/:productSymbol?'),
      search,
    })),
    filter(({ match }) => Boolean(match) && match.isExact),
    mergeMap(({ match, search }) => {
      const initialProducts = productsSelector(state$.value);
      const initialAssets = assetSelector(state$.value);

      if (isNotEmpty(initialProducts) && isNotEmpty(initialAssets)) {
        return of({
          params: match?.params,
          products: initialProducts,
          assets: initialAssets,
          search,
        });
      }

      const products$ = state$.pipe(map(productsSelector), filter(isNotEmpty), take(1));
      const assets$ = state$.pipe(map(assetSelector), filter(isNotEmpty), take(1));

      // we couldn't find the product, Hence waiting for products and assets api to return response.
      return combineLatest([products$, assets$]).pipe(
        map(([products, assets]) => ({ params: match?.params, products, assets, search }))
      );
    }),
    map(findProductAndRedirect)
  );

// epic to unsubscribe from orderbook and recent trade when user navigates to other pages than trade page
const tradeUnsubscribeEpic = action$ =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    map(({ payload }) => ({
      path: payload.location.pathname,
      search: payload.location.search,
    })),
    pairwise(),
    filter(([{ path: previousPath }, { path: currentPath }]) => {
      return isTradePage(previousPath) && !isTradePage(currentPath);
    }),
    mergeMap(() => {
      // unsubscribe from orderbook and recent trade and set selected product to null
      return of(
        unsubscribeL2Orderbook(),
        unsubscribeObRt(),
        unsubscribeBasketOrderOrderbook(),
        changeSelectedProduct(null)
      );
    })
  );

export default combineEpics(
  tradeLocationEpic,
  marketsLocationEpic,
  chartLocationEpic,
  tradeUnsubscribeEpic
);
