/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, forwardRef, ReactElement, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { StylesProvider } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import cx from 'classnames';

import { noop } from 'helpers';
import { CrossIcon } from 'styles/assets/icons';
import IconButton from 'UIKit/IconButton';

import classes from './materialDialog.module.scss';

interface MobileDialogProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  ariaLabel?: string;
  darkOverlay?: boolean;
  lightOverlay?: boolean;
  marginTopInherit?: boolean;
  transition?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  contentClassName?: string;
  disableScrollLock?: boolean;
  rootClassName?: string;
  modalTestId?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MaterialDialog = ({
  open,
  onClose,
  darkOverlay,
  lightOverlay,
  ariaLabel,
  marginTopInherit,
  transition,
  children,
  fullScreen,
  fullWidth,
  onAfterOpen,
  onAfterClose,
  contentClassName,
  disableScrollLock = false,
  rootClassName = '',
  modalTestId = '',
}: MobileDialogProps) => {
  useEffect(() => {
    onAfterOpen?.();
    return () => {
      onAfterClose?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // Using StylesProvider so that material ui prioritizes custom styles over default styles
    <StylesProvider injectFirst>
      <Dialog
        onClose={onClose}
        open={open}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        BackdropProps={{
          classes: {
            // root: darkOverlay ? classes.darkOverlay : '',
            root: cx({
              [classes.darkOverlay]: darkOverlay,
              [classes.lightOverlay]: lightOverlay,
            }),
          },
        }}
        maxWidth={false}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(transition ? { TransitionComponent: Transition } : {})}
        PaperProps={{
          elevation: 0,
          classes: {
            root: cx(
              marginTopInherit
                ? `${classes.paperRoot} ${classes.marginTopInherit} align-items-center`
                : `${classes.paperRoot} align-items-center`,
              rootClassName
            ),
          },
        }}
        aria-labelledby={ariaLabel || 'MaterialDialog dialog'}
        disableScrollLock={disableScrollLock}
        data-testid={modalTestId}
      >
        <DialogContent
          classes={{
            root: cx(classes.dialogContent, {
              [contentClassName as string]: contentClassName,
            }),
          }}
        >
          {children}
        </DialogContent>
      </Dialog>
    </StylesProvider>
  );
};

MaterialDialog.defaultProps = {
  transition: true,
  fullWidth: true,
};

const CloseButton = ({
  closeModal,
  testId,
}: {
  closeModal: () => void;
  testId: string;
}) => {
  return (
    <IconButton
      data-testid={testId}
      icon={<CrossIcon className={classes.crossIcon} />}
      onClick={closeModal}
      onKeyDown={noop}
      className={classes.closeButton}
    />
  );
};

MaterialDialog.CloseButton = CloseButton;

const Header = ({ title }: { title: string }) => {
  return <div className={classes.header}>{title}</div>;
};

MaterialDialog.Header = Header;

export type { MobileDialogProps };
export default MaterialDialog;
