import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { useHistory, useRouteMatch } from 'react-router';

import {
  biometricsLoginAuthorizedByUser,
  biometricsLoginSwitchToSubaccount,
  cleanUpSessionByLoginViaBiometrics,
} from 'actions/auth';
import { postMessageToMobileApp } from 'components/app/helpers';
import { IS_RN_WEBVIEW } from 'constants/constants';
import { TIME_IN_MS } from 'constants/dateTime';
import { POST_MESSAGE_TO_MOBILE_EVENTS, STORAGE_KEYS } from 'constants/enums';
import { FEATURE_FLAG_BIOMETRICS_AUTH_ENABLED } from 'constants/rnApp';
import routes from 'constants/routes';
import useLocalStorage from 'hooks/useLocalStorage';
import useRunOnce from 'hooks/useRunOnce';
import { getUserId, isUserLoggedInSelector } from 'selectors';
import { useAppDispatch, useAppSelector } from 'storeHooks';

import { POST_EVENT, RECEIVED_EVENT, CONFIG_STATUS } from './useBiometrics.constants';
import {
  PostEvent,
  BiometricsConfig,
  BiometricsContext as BiometricsContextType,
  BiometricsLoginMetadata,
} from './useBiometrics.types';

const DEFAULT_CONTEXT_VALUE: BiometricsContextType = {
  config: null,
  onBiometricsPreferenceChange: () => {},
  authenticateUserWithBiometrics: () => {},
  onMessageFromApp: () => {},
};

const BiometricsContext = createContext<BiometricsContextType>(DEFAULT_CONTEXT_VALUE);

const useBiometrics = () => {
  const context = useContext(BiometricsContext);

  if (!context) {
    return DEFAULT_CONTEXT_VALUE;
  }

  return context;
};

function postBiometricsMessage(type: PostEvent, payload: any) {
  postMessageToMobileApp(POST_MESSAGE_TO_MOBILE_EVENTS.BIOMETRICS_MESSAGES, {
    data: { type, payload },
  });
}

const BiometricProvider = ({ children }: { children?: ReactNode | ReactNode[] }) => {
  const [isPageReloaded] = useLocalStorage(STORAGE_KEYS.PAGE_RELOADED, false);

  const [config, setConfig] = useLocalStorage<BiometricsConfig | null>(
    STORAGE_KEYS.BIOMETRICS_CONFIG,
    null
  );

  const history = useHistory();
  const isCurrentRouteOfLoginPage = useRouteMatch(routes.login);

  const appDispatch = useAppDispatch();

  const [biometricsLoginMetadata, setBiometricsLoginMetadata] =
    useLocalStorage<BiometricsLoginMetadata>(
      STORAGE_KEYS.BIOMETRICS_LOGIN_METADATA,
      null
    );

  const isUserLoggedIn = useAppSelector(isUserLoggedInSelector);
  const authorizedUserId = useAppSelector(getUserId);

  const isBiometricsEnabled = config?.status === CONFIG_STATUS.ENABLED;

  const resetCurrentSession = useCallback(() => {
    if (isBiometricsEnabled && !isPageReloaded) {
      if (isUserLoggedIn) {
        appDispatch(cleanUpSessionByLoginViaBiometrics());
      }

      history.replace({
        pathname: routes.login,
        // * Passing previous history.location as `from `to enable redirect back to To location after logging in.
        // * Check Login Component implementation.
        state: { from: { ...history.location } },
      });

      postBiometricsMessage(POST_EVENT.BIOMETRICS_LOGOUT_ON_INIT, {});
      postMessageToMobileApp(POST_MESSAGE_TO_MOBILE_EVENTS.APP_LOADED, {});
    }
  }, [appDispatch, history, isBiometricsEnabled, isPageReloaded, isUserLoggedIn]);

  // * INFO: Logout user by default on app load and if biometrics is enabled
  // * user is logged out on app load and when biometrics is enabled because:
  // * 1. we don't want to keep user logged in,
  // * 2. to prevent flashing of the app content before user is logged out
  useRunOnce({
    sessionKey: STORAGE_KEYS.BIOMETRICS_LOGOUT_ON_INIT,
    fn: resetCurrentSession,
  });

  useEffect(() => {
    if (biometricsLoginMetadata?.id && isUserLoggedIn) {
      if (biometricsLoginMetadata?.id === authorizedUserId) {
        setBiometricsLoginMetadata(null);
      }
    }
  }, [
    authorizedUserId,
    biometricsLoginMetadata?.id,
    isUserLoggedIn,
    setBiometricsLoginMetadata,
  ]);

  const onBiometricsPreferenceChange = useCallback((event, preference: boolean) => {
    postBiometricsMessage(POST_EVENT.BIOMETRICS_PREFERENCE_CHANGE, { preference });
  }, []);

  const authenticateUserWithBiometrics = useCallback(() => {
    postBiometricsMessage(POST_EVENT.BIOMETRICS_AUTHENTICATE_USER, {});
  }, []);

  const onMessageFromApp = useCallback(
    async (event: MessageEvent) => {
      const { type, payload } = event.data ?? {};

      switch (type) {
        case RECEIVED_EVENT.CONFIG_UPDATE: {
          const shouldResetSession = payload?.status === CONFIG_STATUS.INIT;

          if (shouldResetSession) {
            resetCurrentSession();
          }

          setConfig(payload);

          break;
        }

        case RECEIVED_EVENT.USER_AUTHENTICATED: {
          setBiometricsLoginMetadata({
            id: payload?.subAccountUserId ?? payload?.user?.id,
            isSubAccount: Boolean(payload?.subAccountUserId),
          });

          appDispatch(biometricsLoginAuthorizedByUser({ user: payload?.user ?? null }));

          if (payload?.subAccountUserId) {
            appDispatch(
              biometricsLoginSwitchToSubaccount({
                userId: payload?.subAccountUserId ?? null,
              })
            );
          }

          break;
        }

        case RECEIVED_EVENT.STATUS_UPDATE: {
          break;
        }

        default: {
          break;
        }
      }
    },
    [appDispatch, resetCurrentSession, setBiometricsLoginMetadata, setConfig]
  );

  // * INFO: Keeping this bool flag outside useEffect to avoid repetitive authentication calls
  const shouldAuthenticationOnLoginPage =
    isCurrentRouteOfLoginPage && config?.status === CONFIG_STATUS.ENABLED;

  useEffect(() => {
    if (shouldAuthenticationOnLoginPage) {
      authenticateUserWithBiometrics();
    }
  }, [authenticateUserWithBiometrics, shouldAuthenticationOnLoginPage]);

  const defaultProviderValues = useMemo(
    () => ({
      config,
      onBiometricsPreferenceChange,
      authenticateUserWithBiometrics,
      onMessageFromApp,
    }),
    [
      config,
      onBiometricsPreferenceChange,
      authenticateUserWithBiometrics,
      onMessageFromApp,
    ]
  );

  return (
    <BiometricsContext.Provider value={defaultProviderValues}>
      {children}
    </BiometricsContext.Provider>
  );
};

const BiometricsInitializer = ({ children }) => {
  const [isPageReloaded, setIsPageReloaded] = useLocalStorage(
    STORAGE_KEYS.PAGE_RELOADED,
    false
  );

  useEffect(() => {
    if (isPageReloaded) {
      setTimeout(() => {
        setIsPageReloaded(false);
      }, TIME_IN_MS.ONE_SECOND * 3);
    }
  }, [isPageReloaded, setIsPageReloaded]);

  if (!IS_RN_WEBVIEW || !FEATURE_FLAG_BIOMETRICS_AUTH_ENABLED) {
    return children;
  }

  return <BiometricProvider>{children}</BiometricProvider>;
};

export default BiometricsInitializer;

export { useBiometrics };
