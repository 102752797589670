import React, { createContext, useContext, useMemo, useState } from 'react';

import { OAuthProvider } from 'components/Auth/Common/interface';
import { IS_RN_WEBVIEW } from 'constants/constants';
import { FEATURE_FLAG_OAUTH_ENABLED } from 'constants/rnApp';

type StatusType = 'pending' | 'success' | 'failed';

interface OAuthState {
  provider: OAuthProvider;
  status: StatusType;
  error: null | any;
  data: null | {
    email: string;
    accessToken: string;
    authorizationCode?: string;
    identityToken?: string;
  };
}

interface OAuthContextType extends OAuthState {
  setOAuthLoginState: (newState?: OAuthState) => void;
}

const DEFAULT_STATE: OAuthState = {
  provider: 'email',
  status: 'pending',
  error: null,
  data: null,
};

const DEFAULT_CONTEXT_VALUE: OAuthContextType = {
  ...DEFAULT_STATE,
  setOAuthLoginState: () => {},
};

const OAuthContext = createContext<OAuthContextType>(DEFAULT_CONTEXT_VALUE);

const useOAuthLogin = () => {
  const context = useContext(OAuthContext);

  if (!context) {
    return DEFAULT_CONTEXT_VALUE;
  }

  return context;
};

const OAuthLoginProvider = ({ children }) => {
  const [state, setState] = useState<OAuthState>(DEFAULT_STATE);

  const setOAuthLoginState = (newState: OAuthState = DEFAULT_STATE) => {
    setState(newState);
  };

  const defaultProviderValues = useMemo(
    () => ({ ...state, setOAuthLoginState }),
    [state]
  );

  return (
    <OAuthContext.Provider value={defaultProviderValues}>
      {children}
    </OAuthContext.Provider>
  );
};

const OAuthInitializer = ({ children }) => {
  if (IS_RN_WEBVIEW && !FEATURE_FLAG_OAUTH_ENABLED) return children;

  return <OAuthLoginProvider>{children}</OAuthLoginProvider>;
};

export default OAuthInitializer;

export { useOAuthLogin, DEFAULT_STATE };
