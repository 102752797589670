import React from 'react';

const ExternalLink = (props: any) => {
  const { className, onClick, width, height, color } = props;

  return (
    <svg
      data-testid="tAyCozDnHNJdzj0arJ4o0"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={width || '12'}
      height={height || '12'}
      viewBox="0 0 12 12"
      color={color}
    >
      <g fill="none">
        <path
          fill="currentColor"
          d="M4.667 2v1.333H1.333v7.334h7.334V7.333H10v4a.666.666 0 01-.667.667H.667A.666.666 0 010 11.333V2.667C0 2.298.298 2 .667 2h4zM12 0v5.333h-1.333V2.275L5.47 7.471l-.94-.941 5.194-5.196H6.667V0H12z"
        />
      </g>
    </svg>
  );
};

export default ExternalLink;
