/* eslint-disable react/jsx-props-no-spreading */
// IMPORT POPOVER FROM THE CONTAINER. NOT FROM HERE.

import React, { useState, useEffect } from 'react';

import ClickOutside from 'components/click-outside';
import {
  POPOVER_AUTO_CLOSE_DELAY,
  POPOVER_CLOSE_PREV_THRESHOLD,
} from 'constants/constants';
import { is } from 'helpers/ramda';
import useMediaQuery from 'hooks/useMediaQuery';

import PopoverBase from './popover_base';
import { StyledPopover } from './popover_styles';
import PopoverWindow from './popover_window';

const getPopoverProps = (extend = {}) => ({
  leaveTouchDelay: 120000,
  interactive: true,
  ...extend,
});

let timer = null;

const Popover = props => {
  const {
    children,
    placement,
    isInteractive,
    leaveDelay,
    baseProps,
    shouldClosePrevPopover,
    togglePrevPopover,
    shouldEnableClickListener,
    shouldDisablePortal,
    enterDelay,
    enterNextDelay,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [autoCloseAfterDelay, setAutoCloseAfterDelay] = useState(false);
  const { whenAboveMd } = useMediaQuery();

  // Want effect to depend only on autoCloseAfterDelay & shouldClosePrevPopover.
  // Hence disabling eslint
  useEffect(() => {
    // Don't proceed if leaveDelay is less than 1 second
    // For UX and performance improvements
    if (leaveDelay < POPOVER_CLOSE_PREV_THRESHOLD) {
      return;
    }

    if (open) {
      if (shouldClosePrevPopover) {
        return setOpen(false);
      }

      if (shouldEnableClickListener && whenAboveMd) {
        timer = setTimeout(() => {
          setOpen(false);
        }, leaveDelay);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [autoCloseAfterDelay, shouldClosePrevPopover]); // eslint-disable-line

  const closePrevPopper = () => {
    const popper = document.querySelectorAll('.popper');
    if (popper.length === 2) {
      togglePrevPopover(true);
    }
  };

  const onOpen = e => {
    if (leaveDelay > POPOVER_CLOSE_PREV_THRESHOLD) {
      closePrevPopper();
    }
    setOpen(true);
  };

  const onClose = e => {
    if (leaveDelay > POPOVER_CLOSE_PREV_THRESHOLD) {
      togglePrevPopover(false);
    }
    setOpen(false);
  };

  const resetTime = () => clearTimeout(timer);

  const onOutsideClick = () => {
    if (shouldEnableClickListener) {
      setAutoCloseAfterDelay(false);
      resetTime();
    }
    setOpen(false);
  };

  const onWindowMouseEnter = () => {
    if (shouldEnableClickListener) {
      resetTime();
      setAutoCloseAfterDelay(true);
      return;
    }
    if (leaveDelay > POPOVER_CLOSE_PREV_THRESHOLD) {
      setAutoCloseAfterDelay(false);
      togglePrevPopover(false);
    }
  };

  const onWindowMouseLeave = () => {
    if (shouldEnableClickListener) {
      setAutoCloseAfterDelay(false);
      resetTime();
      return;
    }
    if (leaveDelay > POPOVER_CLOSE_PREV_THRESHOLD) {
      setAutoCloseAfterDelay(true);
      togglePrevPopover(true);
    }
  };

  const popoverProps = getPopoverProps({
    title: (
      <span onMouseEnter={onWindowMouseEnter} onMouseLeave={onWindowMouseLeave}>
        <PopoverWindow
          isInteractive={isInteractive}
          shouldDisablePortal={shouldDisablePortal}
          onClose={() => {
            setOpen(false);
          }}
          {...rest}
        />
      </span>
    ),
    placement,
    disableHoverListener: shouldEnableClickListener,
    open,
    onOpen,
    onClose,
    PopperProps: {
      className: 'popper',
      disablePortal: shouldDisablePortal,
    },
    leaveDelay,
    enterDelay,
    enterNextDelay,
  });

  const child = is(Function, children)
    ? children({
      open,
      shouldEnableClickListener,
      setOpen,
      toggleOpen: () => {
        if (shouldEnableClickListener) {
          setAutoCloseAfterDelay(true);
        }
        setOpen(!open);
      },
    })
    : children;

  return (
    <ClickOutside onOutsideClick={onOutsideClick}>
      <StyledPopover {...popoverProps}>
        <span>{child}</span>
      </StyledPopover>
    </ClickOutside>
  );
};

Popover.defaultProps = {
  placement: 'top',
  isInteractive: false,
  children: '',
  showInfoIconOnWindow: false,
  showTip: false,
  header: '',
  shouldEnableClickListener: false,
  className: '',
  style: {},
  leaveDelay: POPOVER_AUTO_CLOSE_DELAY,
  shouldDisablePortal: false,
  enterDelay: 0,
  enterNextDelay: 0,
};

// Popover.propTypes = {
//   children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
//   body: PropTypes.node.isRequired,
//   shouldClosePrevPopover: PropTypes.bool.isRequired,
//   togglePrevPopover: PropTypes.func.isRequired,
//   placement: PropTypes.oneOf([
//     'bottom-end',
//     'bottom-start',
//     'bottom',
//     'left-end',
//     'left-start',
//     'left',
//     'right-end',
//     'right-start',
//     'right',
//     'top-end',
//     'top-start',
//     'top',
//   ]),
//   isInteractive: PropTypes.bool,
//   footerConfig: PropTypes.popoverFooterConfig,
//   showInfoIconOnWindow: PropTypes.bool,
//   showTip: PropTypes.bool,
//   header: PropTypes.node,
//   shouldEnableClickListener: PropTypes.bool,
//   style: PropTypes.object,
//   className: PropTypes.string,
//   leaveDelay: PropTypes.number,
//   shouldDisablePortal: PropTypes.bool,
// };

export { PopoverBase, PopoverWindow };

export default Popover;
