const InfoOutlined = ({
  width = '14',
  height = '14',
  color = 'var(--highlight)',
  className,
}: {
  width?: string;
  height?: string;
  color?: string;
  className?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    className={className}
  >
    <path
      d="M6.99992 13.6667C3.31792 13.6667 0.333252 10.682 0.333252 7C0.333252 3.318 3.31792 0.333336 6.99992 0.333336C10.6819 0.333336 13.6666 3.318 13.6666 7C13.6666 10.682 10.6819 13.6667 6.99992 13.6667ZM6.99992 12.3333C8.41441 12.3333 9.77096 11.7714 10.7712 10.7712C11.7713 9.77105 12.3333 8.41449 12.3333 7C12.3333 5.58551 11.7713 4.22896 10.7712 3.22877C9.77096 2.22857 8.41441 1.66667 6.99992 1.66667C5.58543 1.66667 4.22888 2.22857 3.22868 3.22877C2.22849 4.22896 1.66659 5.58551 1.66659 7C1.66659 8.41449 2.22849 9.77105 3.22868 10.7712C4.22888 11.7714 5.58543 12.3333 6.99992 12.3333ZM6.33325 3.66667H7.66659V5H6.33325V3.66667ZM6.33325 6.33334H7.66659V10.3333H6.33325V6.33334Z"
      fill="currentColor"
    />
  </svg>
);

export default InfoOutlined;
