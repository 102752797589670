/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react';

const CrossIcon = ({ color, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="var(--primaryText)"
    {...props}
  >
    <path
      d="m7 5.586 4.95-4.95 1.414 1.414L8.414 7l4.95 4.95-1.414 1.414L7 8.414l-4.95 4.95L.636 11.95 5.586 7 .636 2.05 2.05.636 7 5.586Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default CrossIcon;
