/* eslint-disable react/prop-types */
import React from 'react';

import styled from 'styled-components';

import Loader from './loader';

const CenterLoaderWrapper = styled.div`
  font-size: 8px;

  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingWrapper = styled.div`
  background-color: ${props => props.theme.primaryBackground}c4;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  justify-content: center;

  .loader {
    position: absolute;
    top: 40%;
    left: 48%;
  }
`;

const Loading = ({ isLoading }) => (
  <div>
    {isLoading ? (
      <LoadingWrapper>
        <Loader className="loader" />
      </LoadingWrapper>
    ) : null}
  </div>
);

export const OverlayLoader = Parent =>
  // eslint-disable-next-line func-names
  function (props) {
    return (
      <>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <Loading isLoading={props.isLoading} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Parent {...props} />
      </>
    );
  };

export const CenterLoader = () => (
  <CenterLoaderWrapper className="center-loader">
    <Loader className="loader" />
  </CenterLoaderWrapper>
);
