import React, { SVGProps } from 'react';

interface IconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const InfoFilled = ({ color, ...props }: IconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 46 46" {...props}>
    <path
      fill={color || 'currentColor'}
      d="M23 .5C10.574.5.5 10.574.5 23S10.574 45.5 23 45.5 45.5 35.426 45.5 23 35.426.5 23 .5Zm-2.25 15.75v-4.5h4.5v4.5h-4.5Zm0 18v-13.5h4.5v13.5h-4.5Z"
    />
  </svg>
);

export default InfoFilled;
