/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { memo } from 'react';

import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { withTheme } from 'styled-components';

import Render from 'components/render';
import { isAbsoluteUrl } from 'helpers/utils';
import i18n from 'i18n/config';
// import PropTypes from 'PropTypes';
import { CrossIconSymbol, ExternalLink, InfoIcon } from 'styles/assets/icons';

import {
  FooterButton,
  PopoverFooter,
  PopoverHead,
  StyledPopoverWindow,
} from './popover_styles';

export const defaultPopoverHeaderConfig = {
  headerStyle: {},
};

export const defaultPopoverFooterConfig = {
  label: i18n.t('common:learnMore'),
  fullWidth: true,
  onClick: () => null,
  href: '',
  btnAlignment: 'right',
  footerStyle: {},
};

const Button = withTheme(({ fullWidth, onClick, label, href, theme, footerStyle }) => (
  <FooterButton
    data-testid="NCpG7yYeca4TZzx0T7Fr1"
    fullWidth={fullWidth}
    onClick={onClick}
    footerStyle={footerStyle}
  >
    {label}
    <Render when={href}>
      <ExternalLink className="external-link-icon" />
    </Render>
  </FooterButton>
));

const ButtonDecider = ({ fullWidth, onClick, label, href, footerStyle }) => {
  const isAbsUrl = isAbsoluteUrl(href);
  const btnProps = {
    fullWidth,
    onClick,
    label,
    href,
    footerStyle,
  };

  if (href) {
    if (isAbsUrl) {
      return (
        <a
          className={classnames('w-100', 'd-inline-block')}
          data-testid="abs-url"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button {...btnProps} />
        </a>
      );
    }
    return (
      <Link to={href}>
        <Button {...btnProps} />
      </Link>
    );
  }
  return <Button {...btnProps} />;
};

const PopoverWindow = props => {
  const {
    header,
    body,
    showTip,
    isInteractive,
    showInfoIconOnWindow,
    headerConfig,
    footerConfig,
    onClose,
    theme,
    style,
    className,
    shouldDisablePortal,
    headerfooterInteractive,
    closeIconClassname,
  } = props;
  const { label, fullWidth, onClick, href, btnAlignment, footerStyle, headerStyle } = {
    ...defaultPopoverHeaderConfig,
    ...defaultPopoverFooterConfig,
    ...footerConfig,
    ...headerConfig,
  };

  const onButtonClick = e => {
    onClose();
    onClick && onClick(e);
  };

  const onCloseClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  return (
    <StyledPopoverWindow
      addExtraTopMargin={!header && isInteractive}
      shouldDisablePortal={shouldDisablePortal}
      style={style}
      className={className}
    >
      <PopoverHead headerStyle={headerStyle}>
        <Render when={isInteractive}>
          <button
            data-testid="2hQga-tuPfqqtEfEBzxqX"
            type="button"
            className={`close-icon-btn ${closeIconClassname || ''}`}
            onClick={onCloseClick}
            title={i18n.t('common:close')}
          >
            <CrossIconSymbol className="close-icon" color={theme.secondaryText} />
          </button>
          <div className="close-icon-spacer" />
        </Render>
        <Render when={header}>
          <div className="header">{header}</div>
        </Render>
        <div className="body-wrapper">
          <Render when={showInfoIconOnWindow}>
            <div className="info-icon">
              <InfoIcon />
            </div>
          </Render>
          <div className="body">{body}</div>
        </div>
        <Render when={showTip}>
          <div className="showTip">{i18n.t('common:tapToClose')}</div>
        </Render>
      </PopoverHead>

      <Render when={isInteractive || headerfooterInteractive}>
        <PopoverFooter
          fullWidth={fullWidth}
          btnAlignment={btnAlignment}
          footerStyle={footerStyle}
        >
          <ButtonDecider
            data-testid="EZYixBZ4Z21dBcA1H8OA1"
            fullWidth={fullWidth}
            href={href}
            label={label}
            onClick={onButtonClick}
            footerStyle={footerStyle}
          />
        </PopoverFooter>
      </Render>
    </StyledPopoverWindow>
  );
};

// PopoverWindow.propTypes = {
//   theme: PropTypes.theme.isRequired,
//   body: PropTypes.node.isRequired,
//   isInteractive: PropTypes.bool.isRequired,
//   headerConfig: PropTypes.popoverHeaderConfig,
//   footerConfig: PropTypes.popoverFooterConfig,
//   showTip: PropTypes.bool.isRequired,
//   showInfoIconOnWindow: PropTypes.bool.isRequired,
//   header: PropTypes.node.isRequired,
//   onClose: PropTypes.func.isRequired,
//   style: PropTypes.object.isRequired,
//   className: PropTypes.string.isRequired,
//   shouldDisablePortal: PropTypes.bool.isRequired,
// };

// const enhance = compose(withTheme, onlyUpdateForKeys(['body']));

export default memo(withTheme(PopoverWindow));
