/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react';

const NewRedirectIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.667 2v1.333H1.333v7.334h7.334V7.333H10v4a.667.667 0 0 1-.667.667H.667A.667.667 0 0 1 0 11.333V2.667C0 2.298.298 2 .667 2h4ZM12 0v5.333h-1.333V2.275L5.47 7.471 4.53 6.53l5.194-5.196H6.667V0H12Z"
    />
  </svg>
);
export default NewRedirectIcon;
